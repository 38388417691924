<template>
  <v-dialog persistent width="600" v-model="opened">
    <v-flex class="fonte" xs12>
      <v-card style="border-radius: 12px;">
        <v-flex xs12>
          <div class="windows-style" style="border-top-right-radius: 12px; border-top-right-radius: 12px;">
            <div class="expande-horizontal">
              <v-flex xs12>
                <v-list color="transparent" dense nav>
                  <v-list-item v-if="step == '0'">
                    <v-avatar @click="$router.push('/dashboard')" :size="31" style="cursor: pointer;" class="mr-2" :color="$theme.primary">
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">Triagem</span>
                      </v-list-item-title>
                      <span class="fonte grey--text"></span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <span class="grey--text">1 de 4</span>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="step == '1'">
                    <v-avatar @click="$router.push('/dashboard')" :size="31" style="cursor: pointer;" class="mr-2" :color="$theme.primary">
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">{{ triage.o_que_voce_esta_sentindo.title }}</span>
                      </v-list-item-title>
                      <span class="fonte grey--text">{{ triage.o_que_voce_esta_sentindo.description }}</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <span class="grey--text">2 de 4</span>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="step == '2'">
                    <v-avatar @click="$router.push('/dashboard')" :size="31" style="cursor: pointer;" class="mr-2" :color="$theme.primary">
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">{{ triage.problema_de_saude.title }}</span>
                      </v-list-item-title>
                      <span class="fonte grey--text">{{ triage.problema_de_saude.description }}</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <span class="grey--text">3 de 4</span>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="step == '3'">
                    <v-avatar @click="$router.push('/dashboard')" :size="31" style="cursor: pointer;" class="mr-2" :color="$theme.primary">
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">{{ triage.alergia.title }}</span>
                      </v-list-item-title>
                      <span class="fonte grey--text">{{ triage.alergia.description }}</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <span class="grey--text">4 de 4</span>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="step == '4'">
                    <v-avatar @click="$router.push('/dashboard')" :size="31" style="cursor: pointer;" class="mr-2" :color="$theme.primary">
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte"> Tudo certo </span>
                      </v-list-item-title>
                      <span class="fonte grey--text">próximos passos</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-check</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-flex>
            </div>
          </div>
        </v-flex>
        <div class="pa-3 windows-style-content">
          <v-window v-model="step">
            <v-window-item value="0">
              <div class="expande-horizontal centraliza wrap">
                <v-flex class="pa-3" xs12>
                  <div class="expande-horizontal wrap centraliza py-6">
                    <div style="width: 300px;">
                      <LottieAnimation
                        class="pa-6 pb-0"
                        ref="anim"
                        :loop="true"
                        :animationData="require('@/assets/doctor.json')
                        "
                      />
                    </div>
                    <v-flex class="px-12" xs12>
                      <span class="grey--text fonte centraliza expande-horizontal text-center" style="font-size: 12pt; text-align: center !important;">Olá, para que possamos lhe encaminhar ao médico, precisamos que nos responda algumas perguntas ok.</span>
                    </v-flex>
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-1" xs12>
                  <div class="expande-horizontal centraliza px-3">
                    <v-btn rounded class="mr-2" color="primary" @click="setStep(1)">Entendi <v-icon>mdi-chevron-right</v-icon> </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>
            <v-window-item value="1">
              <div class="expande-horizontal wrap">
                <v-flex class="pa-3" xs12>
                  <v-chip :dark="opt.value" :color="opt.value ? $theme.primary : ''" @click="opt.value = !opt.value" v-for="opt in triage.o_que_voce_esta_sentindo.options" :key="opt.text" class="mr-2 mb-2">
                    <v-icon size="17" class="mr-2" v-if="!opt.value">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon size="17" class="mr-2" v-else>mdi-checkbox-marked-circle-outline</v-icon>
                    {{ opt.text }}
                  </v-chip>
                </v-flex>
                <v-flex class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centralize px-3">
                    <v-btn rounded class="ml-2" color="primary" @click="setStep(0)" text><v-icon>mdi-chevron-left</v-icon> Anterior </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn rounded class="mr-2" color="primary" @click="setStep(2)">Próximo <v-icon>mdi-chevron-right</v-icon> </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>
            <v-window-item value="2">
              <div class="expande-horizontal wrap">
                <v-flex class="pa-3" xs12>
                  <div class="expande-horizontal centraliza">
                    <v-chip 
                      :dark="triage.problema_de_saude.response == 'não'" 
                      :color="triage.problema_de_saude.response === 'não' ? $theme.primary : ''" 
                      @click="triage.problema_de_saude.response = 'não'" 
                      class="mr-2 mb-2"
                    >
                      <v-icon size="17" class="mr-2" v-if="triage.problema_de_saude.response != 'não'">mdi-checkbox-blank-circle-outline</v-icon>
                      <v-icon size="17" class="mr-2" v-if="triage.problema_de_saude.response == 'não'">mdi-checkbox-marked-circle-outline</v-icon>
                      Não
                    </v-chip>
                    <v-chip 
                      :dark="triage.problema_de_saude.response == 'sim'" 
                      :color="triage.problema_de_saude.response === 'sim' ? $theme.primary : ''" 
                      @click="triage.problema_de_saude.response = 'sim'" 
                      class="mr-2 mb-2"
                    >
                      <v-icon size="17" class="mr-2" v-if="triage.problema_de_saude.response != 'sim'">mdi-checkbox-blank-circle-outline</v-icon>
                      <v-icon size="17" class="mr-2" v-if="triage.problema_de_saude.response == 'sim'">mdi-checkbox-marked-circle-outline</v-icon>
                      Sim
                    </v-chip>
                    
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centralize px-3">
                    <v-btn rounded class="ml-2" color="primary" @click="setStep(1)" text><v-icon>mdi-chevron-left</v-icon> Anterior </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn rounded class="mr-2" color="primary" @click="setStep(3)">Próximo <v-icon>mdi-chevron-right</v-icon> </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>
            <v-window-item value="3">
              <div class="expande-horizontal wrap">
                <v-flex class="pa-3" xs12>
                  <div class="expande-horizontal fonte centraliza">
                    <v-list color="transparent" dense nav class="px-0">
                      <v-list-item v-for="opt in triage.alergia.options" :key="opt.text">
                        <v-list-item-content>
                          <span style="font-size: 12pt;" class="fonte">{{ opt.text }}</span>
                          <span>
                            <v-chip 
                            :dark="opt.response == 'não'" 
                            :color="opt.response === 'não' ? $theme.primary : ''" 
                            @click="opt.response = 'não'" 
                            class="mr-2 mb-2"
                          >
                            <v-icon size="17" class="mr-2" v-if="opt.response != 'não'">mdi-checkbox-blank-circle-outline</v-icon>
                            <v-icon size="17" class="mr-2" v-if="opt.response == 'não'">mdi-checkbox-marked-circle-outline</v-icon>
                            Não
                          </v-chip>
                          <v-chip 
                            :dark="opt.response == 'sim'" 
                            :color="opt.response === 'sim' ? $theme.primary : ''" 
                            @click="opt.response = 'sim'" 
                            class="mr-2 mb-2"
                          >
                            <v-icon size="17" class="mr-2" v-if="opt.response != 'sim'">mdi-checkbox-blank-circle-outline</v-icon>
                            <v-icon size="17" class="mr-2" v-if="opt.response == 'sim'">mdi-checkbox-marked-circle-outline</v-icon>
                            Sim
                          </v-chip>
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centralize px-3">
                    <v-btn rounded class="ml-2" color="primary" @click="setStep(2)" text><v-icon>mdi-chevron-left</v-icon> Anterior </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn rounded class="mr-2" color="primary" @click="setStep(4)">Finalizar <v-icon>mdi-chevron-right</v-icon> </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>
            <v-window-item value="4">
              <div class="expande-horizontal wrap">
                <v-flex class="pa-3" xs12>
                  <div class="expande-horizontal py-6 centraliza column">
                    <v-icon color="green" size="60">
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                    <span class="text-center px-6 mt-3">Agora que você passou pela triagem, você será encaminhado para o clínico geral.</span>
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centraliza px-3">
                    <v-btn rounded class="ml-2" color="primary" @click="setStep(3)" text><v-icon>mdi-chevron-left</v-icon> Anterior </v-btn>
                    <v-btn rounded class="mr-2" color="green" dark @click="finalize">Ir para a consulta </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
import LottieAnimation from 'lottie-web-vue';
  export default {
    components: {
      LottieAnimation
    },
    methods: {
      setStep(step) {
        console.log('asd', step);
        this.step = `${step}`
      },
      finalize() {
        this.$emit('input', this.triage)
        this.opened = false
        this.step = '0'
      }
    },
    data() {
      return {
        opened: true,
        step: '0',
        triage: {
          //1
          o_que_voce_esta_sentindo: {
            title: 'O que você está sentindo?',
            description: 'Informe os sintomas ou dores',
            options: [
              {
                text: "Dor de cabeça",
                value: false
              },
              {
                text: "Dor no corpo",
                value: false
              },
              {
                text: "Tosse",
                value: false
              },
              {
                text: "Dor de garganta",
                value: false
              },
              {
                text: "Ansiedade",
                value: false
              },
              {
                text: "Dor no abdômen",
                value: false
              },
              {
                text: "Dor nas costas",
                value: false
              },
              {
                text: "Dor lombar",
                value: false
              },
              {
                text: "Diarréia",
                value: false
              },
              {
                text: "Depressão",
                value: false
              },
              {
                text: "Palpitação",
                value: false
              },
            ]
          },
          //2
          problema_de_saude: {
            title: "Histórico de saúde",
            description: "Você tem algum problema de saúde ou toma medicamento todos os dias?",
            response: "" //sim ou não
          },
          //3
          alergia: {
            title: "Alergia",
            description: "Informe se você tem alergia a medicamentos, contraste ou frutos do mar*",
            options: [
              {
                text: "1. Você tem alergia a algum tipo de medicamento?",
                response: "", //sim, não ou não sei
              },
              {
                text: "2. Você tem alergia a contraste ou iodo?",
                response: "", //sim, não ou não sei
              },
              {
                text: "3. Você tem alergia a frutos do mar?",
                response: "", //sim, não ou não sei
              },
            ]
          }
        }
      }
    }
  }
</script>